<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">{{ product.productName }}</h1>
      <progressIndicator :step="product.step" :steps="product.steps" />
    </div>
    <template v-if="product.step == 1">
      <div class="container small">
        <div class="box">
          <form id="step1" v-on:submit.prevent="submitStep1">
            <!-- Registreringsnummer -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="!product.fields.registrationToggle.value"
                :data="product.fields.registrationNumber"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Toggle knap for registreringsnummer -->
            <FormInput
              :data="product.fields.registrationToggle"
              :product="product"
              v-on:formFunction="formFunction"
            />

            <!-- Information om bil -->
            <VehicleInformation
              :data="product.fields.registrationData"
              :product="product"
            />

            <!-- Vehicle brand -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.vehicleBrand"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Vehicle model -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.vehicleModel"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Model year -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.modelYear"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Fuel type -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.fuelType"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Vehicle variant -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="product.fields.registrationToggle.value"
                :data="product.fields.vehicleVariant"
                :product="product"
                v-on:formFunction="formFunction"
              />
            </transition>
          </form>
        </div>

        <!-- Fejlbesked -->
        <message
          v-show="error.step1"
          :message="error.step1"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 2">
      <div class="container small">
        <div class="box">
          <form id="step2" v-on:submit.prevent="submitStep2">
            <!-- Felter på trin 2 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-if="field.step == 2"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </form>
        </div>
        <!-- Fejlbesked -->
        <message
          v-show="error.step2"
          :message="error.step2"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <template v-else-if="product.step == 3">
      <form id="step3" v-on:submit.prevent="submitStep3">
        <div class="container small">
          <div class="box">
            <!-- Felter på trin 3 -->
            <template v-for="(field, index) in product.fields">
              <FormInput
                v-show="field.step == 3"
                :data="field"
                :product="product"
                :key="index"
                v-on:formFunction="formFunction"
              />
            </template>
          </div>

          <!-- Fejlbesked -->
          <message
            v-show="error.price"
            :message="error.price"
            type="warning"
            v-on:formFunction="formFunction"
          />
        </div>

        <!-- Pakkekomponent -->
        <packages
          altLinkText="Læs mere om vores bilforsikring"
          :packages="product.packages"
          :packageLink="product.packageLink"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />

        <!-- Extra packages -->
        <coverages
          v-model="product.coverages"
          :requirementsMet="allRequirementsMet"
          v-on:packageChoice="choosePackage"
        />
      </form>
    </template>

    <div class="container small">
      <div class="button-flex-container">
        <!-- Back button -->
        <button
          v-if="product.step > 1"
          type="button"
          class="button white justify-start"
          v-on:click.prevent="product.step -= 1"
        >
          Tilbage
        </button>

        <!-- Button step 1 -->
        <button
          v-if="product.step == 1"
          type="submit"
          class="button"
          form="step1"
          :disabled="!requirementsStep1Met || loading.step1"
        >
          <template v-if="!loading.step1">Næste</template>
          <template v-else-if="loading.step1">
            <i class="fa-light fa-spinner-third fa-fw icon-spin" />
          </template>
        </button>

        <!-- Button step 2 -->
        <button
          v-if="product.step == 2"
          type="submit"
          class="button"
          form="step2"
          :disabled="!requirementsStep2Met || loading.step2"
        >
          <template v-if="!loading.step2">Næste</template>
          <template v-else-if="loading.step2">
            <i class="fa-light fa-spinner-third fa-fw icon-spin" />
          </template>
        </button>

        <!-- Button step 3 -->
        <button
          v-if="product.step == 3"
          type="submit"
          class="button"
          form="step3"
          :disabled="!requirementsStep3Met"
        >
          <template v-if="existingPolicy">Opdater indkøbskurv</template>
          <template v-else>Tilføj til indkøbskurv</template>
        </button>
      </div>
    </div>

    <!-- Product footer -->
    <product-footer
      :productName="product.productName"
      :productId="product.productId"
      :productLink="product.productLink"
      v-on:contactFromFooter="messageBox"
    >
      Med vores Bilforsikring får du frit valg af værksted, fri lånebil og
      gratis nummerpladeforsikring, ligesom du også trygt kan låne din bil ud
      til dine egne børn, delebørn og svigerbørn, uden at din selvrisiko stiger,
      hvis der sker en skade.
    </product-footer>
  </div>
</template>
<script>
import M14 from "@/assets/json/M14.json";
import FormInput from "@/components/FormInput.vue";
import progressIndicator from "@/components/progress-indicator.vue";
import VehicleInformation from "@/components/VehicleInformation.vue";
import message from "@/components/message.vue";
import productFooter from "@/components/product-footer.vue";
import packages from "@/components/packages.vue";
import coverages from "@/components/coverages.vue";
import debounce from "lodash/debounce";
import commonProduct from "@/mixins/commonProduct.js";
import validateData from "@/mixins/validateData.js";

export default {
  name: "Bilforsikring",
  components: {
    FormInput,
    progressIndicator,
    VehicleInformation,
    message,
    productFooter,
    packages,
    coverages,
  },
  props: {
    policy: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      productId: "M14",
      product: this.copy(M14),
      temporaryProduct: this.copy(this.$store.state.temporaryProduct),
    };
  },
  mixins: [commonProduct, validateData],
  created() {
    /*
     * If you edit and existing offer copy the policy here, or if you have temporary object
     */
    if (this.policy) {
      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      this.product = this.copy(this.policy.value);
      //this.existingPolicy = true;
      //this.existingPolicyKey = this.policy.key;
    } else if (
      !this.$route.query.input &&
      this.temporaryProduct &&
      this.temporaryProduct.productId == this.productId
    ) {
      this.product = this.copy(this.temporaryProduct);
    }
  },
  mounted() {
    // Function if input has come from website
    if (this.$route.query.input) {
      // Set registration number from input
      this.product.fields.registrationNumber.value = this.$route.query.input;

      // If value is set, run function
      if (this.product.fields.registrationNumber.value) {
        this.getRegistrationNumber();
      }

      // Get query and delete input
      let query = this.copy(this.$route.query);
      query.input = undefined;

      this.$router.push({ name: "Bilforsikring", query: query });
    }
  },
  computed: {
    /*
     * Check requirements for step 1
     */
    requirementsStep1Met() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required && value.step == 1) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },
    /*
     * Check requirements for step 2
     */
    requirementsStep2Met() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required && value.step == 2) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },
    /*
     * Check requirements for step 2
     */
    requirementsStep3Met() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required && value.step == 3) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      if (!this.product.package || this.product.package.loading) {
        return false;
      }
      return true;
    },
    /*
     * Check requirements
     */
    allRequirementsMet() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.product.fields)) {
        if (value.required) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },
  },
  watch: {
    "product.fields": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
    "product.coverages": {
      handler: function () {
        this.calculateEveryPrice();
      },
      deep: true,
    },
  },
  methods: {
    /*
     * When you select dawa option
     */
     async setAddress(option) {
      this.clog("set address", option);

      this.product.fields.customerAddress.error = null;

      this.product.fields.fullAddress.value = option.adressebetegnelse;
      this.product.fields.insuranceStreet.value = option.adgangsadresse.vejstykke.adresseringsnavn;
      this.product.fields.insuranceStreetNumber.value = option.adgangsadresse.husnr;
      this.product.fields.insurancePostalCode.value = option.adgangsadresse.postnummer.nr;
      this.product.fields.zipCode.value = option.adgangsadresse.postnummer.nr;
      this.product.fields.postalCity.value = option.adgangsadresse.postnummer.navn;
      this.product.fields.insuranceEntrance.value = option.dør;
      this.product.fields.insuranceFloor.value = option.etage;
    },
    /*
     * Reset address fields
     */
     resetAddress() {
      this.product.fields.customerAddress.error = null;
      this.product.fields.customerAddress.selected = null;
      this.product.fields.fullAddress.value = null;
      this.product.fields.insuranceStreet.value = null;
      this.product.fields.insuranceStreetNumber.value = null;
      this.product.fields.insurancePostalCode.value = null;
      this.product.fields.zipCode.value = null;
      this.product.fields.postalCity.value = null;
      this.product.fields.insuranceEntrance.value = null;
      this.product.fields.insuranceFloor.value = null;
    },
    /*
     * Get data for registration number from api
     */
    getRegistrationNumber() {
      let self = this;

      // Reset car fields
      self.resetCarFields();

      // Remove error if it's there
      self.product.fields.registrationNumber.error = null;

      // Set registration field to loading
      self.product.fields.registrationNumber.loading = true;

      // Create api string
      let apiString = `/api/vehicle/information/${self.product.fields.registrationNumber.value}`;

      // Get options list
      self.axios
        .get(apiString, {
          headers: {
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
        })
        .then((response) => {
          self.product.fields.registrationData.value = response.data;
          self.setFromData(response.data.data, self.product, {
            'effectFactor': "effectFactorCalculated"
          });
          self.product.fields.registrationNumber.loading = false;
        })
        .then(() => {
          // Track data for retargeting
          self.trackData(
            "registrationNumber",
            self.product,
            self.$store.state.basket
          );

          // Check if step requirements are met
          if (!self.requirementsStep1Met) {
            self.error.step1 = {
              headline: "Vi kan desværre ikke beregne en pris online",
              text: 'En af de kritiske informationer vedr. køretøjet mangler fra SKAT for at kunne beregne en pris online. Indtast oplysningerne om din bil manuelt ved at trykke på knappen "Jeg kender ikke registreringsnummeret", eller udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.',
              button: {
                text: "Kontakt mig",
                function: "messageBox",
              },
            };
          }
        })
        .catch(() => {
          self.product.fields.registrationNumber.error = `Vi kunne ikke finde køretøjsinformation for "${self.product.fields.registrationNumber.value.toUpperCase()}". Dobbelttjek at du har tastet det rigtige nummer ind i feltet.\nHvis du ikke kender din nummerplade, kan du finde din bil manuelt ved at trykke på knappen nedenfor`;
          self.product.fields.registrationNumber.loading = false;
        });
    },
    /*
     * Reset car fields that is updated from registration field
     */
    resetCarFields() {
      this.product.fields.chassisNumber.value = null;
      this.product.fields.chassisType.value = null;
      this.product.fields.effectFactor.value = null;
      this.product.fields.fuelType.value = null;
      this.product.fields.horsepower.value = null;
      this.product.fields.originalPrice.value = null;
      this.product.fields.use.value = null;
      this.product.fields.modelYear.value = null;
      this.product.fields.vehicleBrand.value = null;
      this.product.fields.vehicleName.value = null;
      this.product.fields.vehicleModel.value = null;
      this.product.fields.vehicleVariant.value = null;
      this.product.fields.weightOwn.value = null;
      this.product.fields.wheelDrive.value = null;
      this.product.fields.registrationData.value = null;
      this.error.step1 = null;
    },
    /*
     * Event when you choose a package
     */
    calculateEveryPrice: debounce(function () {
      let self = this;

      // Update temporary product if product is not existing
      if (!self.existingPolicy) {
        self.$store.commit("temporaryProduct", self.product);
      }

      // If all requirements are not met, break from function
      if (!self.allRequirementsMet) return;

      // Remove error
      self.error.price = null;

      // Get submit data without coverages
      let submitData = self.parseSubmitData(self.product);

      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/price`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then(function (response) {
          // If weird error shows, run function again
          if (response.data.objects && response.data.objects.length < 3) {
            self.calculateEveryPrice();
          }

          self.insertPrice(response.data);

          // Track data for retargeting
          self.trackData(
            "calculatePrice",
            self.product,
            self.$store.state.basket
          );
        })
        .then(() => {
          // Show popup
          self.$store.commit("showPopup");

          // Update temporary product if product is not existing
          if (!self.existingPolicy) {
            self.$store.commit("temporaryProduct", self.product);
          }

          // If exiting policy, update existing policy
          if (self.existingPolicy) {
            self.updateExistingPolicy(self.product, self.existingPolicyKey);
          }
        })
        .catch(function () {
          // Show error
          self.error.price = {
            headline: "Vi kan desværre ikke beregne en pris online",
            text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
            button: {
              text: "Kontakt mig",
              function: "messageBox",
            },
          };

          // Remove loading for packages
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(self.product.packages)) {
            value.loading = null;
          }
        });
    }, 250),
    /*
     * Set data when you choose vehicleVariant
     */
    vehicleVariantSelected() {
      this.error.step1 = null;

      this.setFromData(this.product.fields.vehicleVariant.selected.data, this.product, {
        'effectFactor': "effectFactorCalculated"
      });

      if (!this.requirementsStep1Met) {
        this.error.step1 = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: "Det er desværre ikke muligt at beregne en pris online. Udfyld kontaktformularen og vores kundeservice kontakter dig for at give et tilbud på dine forsikringer.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }
    },
    /*
     * Function for input on registration number
     */
    registrationNumber() {
      this.resetCarFields();
      this.product.fields.registrationNumber.error = null;
      this.error.step1 = null;

      // If the registration number is filled out, change to upper case
      if (this.product.fields.registrationNumber.value) {
        this.product.fields.registrationNumber.value =
          this.product.fields.registrationNumber.value.toUpperCase();
      }
    },
    /*
     * When setting vehicle brand, reset other car fields
     */
    setVehicleBrand() {
      this.product.fields.vehicleModel.value = null;
      this.product.fields.modelYear.value = null;
      this.product.fields.fuelType.value = null;
      this.product.fields.vehicleVariant.value = null;
    },
    /*
     * Function when the toggle for registration number changes
     */
    toggleRegistrationData() {
      // Reset car fields
      this.resetCarFields();

      if (this.product.fields.registrationToggle.value) {
        // Remove required from registration number
        this.product.fields.registrationNumber.required = false;

        // Remove required from registration number
        this.product.fields.registrationData.required = false;

        // Remove content of registration number
        this.product.fields.registrationNumber.value = null;
        this.product.fields.registrationNumber.error = null;

        // Set vehicle fields to select
        this.product.fields.vehicleBrand.type = "Select";
        this.product.fields.vehicleModel.type = "Select";
        this.product.fields.modelYear.type = "Select";
        this.product.fields.fuelType.type = "Select";
        this.product.fields.vehicleVariant.type = "Select";
      } else {
        // Add required from registration number
        this.product.fields.registrationNumber.required = true;

        // Add required from registration number
        this.product.fields.registrationData.required = true;

        // Set vehicle fields to hidden
        this.product.fields.vehicleBrand.type = "Hidden";
        this.product.fields.vehicleModel.type = "Hidden";
        this.product.fields.modelYear.type = "Hidden";
        this.product.fields.fuelType.type = "Hidden";
        this.product.fields.vehicleVariant.type = "Hidden";
      }
    },
    /*
     * Submit step 1 in form
     */
    async submitStep1() {
      let self = this;

      // Set step 1 to loading
      self.loading.step1 = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(
        self.product,
        self.product.step
      );

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set loading to false
      self.loading.step1 = false;
    },
    /*
     * Submit step 2 in form
     */
    async submitStep2() {
      let self = this;

      // Set step 1 to loading
      self.loading.step2 = true;

      // Reset validation error
      self.validationError = null;

      // Reset error message
      self.error[`step${self.product.step}`] = null;

      // Validate the data from step 1
      let validationResult = await self.validateData(self.product, self.product.step);

      // Send customer along if valid, otherwise show error
      if (validationResult.isValid) {
        self.product.step += 1;
      } else {
        // Set validation error from response
        self.validationError = validationResult.message;

        // Set error message
        self.error[`step${self.product.step}`] = {
          headline: "Vi kan desværre ikke beregne en pris online",
          text: validationResult.message,
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }

      // Set step 1 to loading
      self.loading.step2 = false;
    },
    /*
     * Submit step 3 in form
     */
    submitStep3() {
      // Check if policy is exitting or new
      if (this.existingPolicy) {
        this.updateExistingPolicy(this.product, this.existingPolicyKey);
      } else {
        this.$store.commit("addProduct", this.product);
      }

      // Delete temporary product
      this.$store.commit("deleteTemporaryProduct");

      // Route to shopping basket
      this.$router.push({ name: "Kurv" });
    },
    /*
     * Update existing policy
     */
    updateExistingPolicy(product, key) {
      this.$store.commit("updateProduct", {
        value: product,
        key: key,
      });
    },
  },
};
</script>
